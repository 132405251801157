import React from 'react';
import Layout from '../components/App/Layout';
import Navbar from '../components/App/Navbar';
import PageBanner from '../components/Common/PageBanner';
import Footer from '../components/App/Footer';
import PricingWrapper from './pricing.style';
import {Link} from 'gatsby';
import duplicateEntries from '../assets/images/questions/duplicate-entries.png';
import StartProject from '../components/Common/StartProject';
import SEO from '../components/App/SEO';
import metaImage from '../assets/images/top-banner-op-image.png';
import ReactTooltip from 'react-tooltip'
import {StaticImage} from 'gatsby-plugin-image';
import {Accordion, AccordionItem, AccordionItemButton, AccordionItemHeading, AccordionItemPanel} from 'react-accessible-accordion';

const Question = (props) => {
    return (
        <span style={{marginLeft: '5px', color:'rgb(179, 179, 179)'}}>
            <a data-tip={props.text}  ><i className="fas fa-question-circle"></i></a>
            <ReactTooltip place="top" html={true} type="dark" effect="solid"/>
        </ span>

    )
};
const Pricing = () => {
    return (
        <Layout>
            <SEO
              title="SNSキャンペーンツールの価格・料金プラン"
              desc="【業界最安値】月額2,980円からの価格・料金プラン。リタッチならキャンペーンの規模に合わせて料金プランを選択できます。"
              banner={metaImage}
              pathname={'/pricing'}
              type={'Product'}
              shortName="SNSキャンペーンツールの料金"
              createdAt="2021-03-02"
            />
            <Navbar />
            <PageBanner
                pageTitle="料金"
                homePageText="Home" 
                homePageUrl="/" 
                activePageText="料金"
            />

            <PricingWrapper className="membership-levels-area">
                <div id="generic_price_table" className="ptb-100">
                <section>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-4 col-sm-12">
                                <div className="generic_content active clearfix">
                                    <div className="generic_head_price clearfix">
                                        <div className="generic_head_content clearfix">
                                            <div className="head_bg"></div>
                                            <div className="head">
                                                <span>フリープラン</span>
                                            </div>
                                        </div>
                                        <div className="generic_price_tag clearfix">
                                        <span className="price">
                                            <span className="sign">￥</span>
                                            <span className="currency">0</span>
                                            {/*<span className="cent">.99</span>*/}
                                            <span className="month">/月</span>
                                        </span>
                                        </div>
                                    </div>
                                    <div className="generic_feature_list">
                                        <ul>
                                            <li><span>キャンペーン機能</span> </li>
                                            <li><span>インスタントウィン機能</span> </li>
                                            <li><span>メールサポート</span> </li>
                                            <li><span>応募データの表示制限</span> 10</li>
                                            <li><span>応募ユーザーの上限数</span> 10</li>
                                            <li><span>リタッチロゴの表示</span> あり</li>
                                            <li><span>応募方法の制限</span> あり</li>
                                            <li><a href="#pricing-header" className="detailPricing" ><span>詳細を見る</span></a></li>
                                        </ul>
                                    </div>
                                    <div className="generic_price_btn clearfix">
                                        <a className="" href="">無料で試す</a>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-4 col-sm-12">
                                <div className="generic_content active clearfix">
                                    <div className="generic_head_price clearfix">
                                        <div className="generic_head_content clearfix">
                                            <div className="head_bg"></div>
                                            <div className="head">
                                                <span>ライトプラン</span>
                                            </div>
                                        </div>
                                        <div className="generic_price_tag clearfix">
                                        <span className="price">
                                            <span className="sign">￥</span>
                                            <span className="currency">2,980</span>
                                            {/*<span className="cent">.99</span>*/}
                                            <span className="month">/月</span>
                                        </span>
                                        </div>
                                    </div>
                                    <div className="generic_feature_list">
                                        <ul>
                                            <li><span>キャンペーン機能</span> </li>
                                            <li><span>インスタントウィン機能</span> </li>
                                            <li><span>メールサポート</span> </li>
                                            <li><span>応募データの表示制限</span> 300</li>
                                            <li><span>応募ユーザーの上限数</span> 300</li>
                                            <li><span>リタッチロゴの表示</span> あり</li>
                                            <li><span>応募方法の制限</span> あり</li>
                                            <li><a href="#pricing-header" className="detailPricing" ><span>詳細を見る</span></a></li>
                                        </ul>
                                    </div>
                                    <div className="generic_price_btn clearfix">
                                        <a className="" href="">無料で試す</a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 col-sm-12">
                                <div className="generic_content active clearfix">
                                    <div className="generic_head_price clearfix">
                                        <div className="generic_head_content clearfix">
                                            <div className="head_bg"></div>
                                            <div className="head">
                                                <span>ベーシックプラン</span>
                                            </div>
                                        </div>
                                        <div className="generic_price_tag clearfix">
                                        <span className="price">
                                            <span className="sign">￥</span>
                                            <span className="currency">9,800</span>
                                            {/*<span className="cent">.99</span>*/}
                                            <span className="month">/月</span>
                                        </span>
                                        </div>
                                    </div>
                                    <div className="generic_feature_list">
                                        <ul>
                                            <li><span>キャンペーン機能</span> </li>
                                            <li><span>インスタントウィン機能</span> </li>
                                            <li><span>UGCギャラリー機能</span> </li>
                                            <li><span>メール・チャットサポート</span> </li>
                                            <li><span>応募データの表示制限</span> 3,000</li>
                                            <li><span>応募ユーザーの上限数</span> 3,000</li>
                                            <li><span>リタッチロゴの表示</span> あり</li>
                                            <li><span>応募方法の制限</span> なし</li>
                                            <li><a href="#pricing-header" className="detailPricing" ><span>詳細を見る</span></a></li>
                                        </ul>
                                    </div>
                                    <div className="generic_price_btn clearfix">
                                        <a className="" href="">無料で試す</a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 col-sm-12">
                                <div className="generic_content active clearfix">
                                    <div className="generic_head_price clearfix">
                                        <div className="generic_head_content clearfix">
                                            <div className="head_bg"></div>
                                            <div className="head">
                                                <span>プロプラン</span>
                                            </div>
                                        </div>
                                        <div className="generic_price_tag clearfix">
                                        <span className="price">
                                            <span className="sign">￥</span>
                                            <span className="currency">39,800</span>
                                            {/*<span className="cent">.99</span>*/}
                                            <span className="month">/月</span>
                                        </span>
                                        </div>
                                    </div>
                                    <div className="generic_feature_list">
                                        <ul>
                                            <li><span>キャンペーン機能</span> </li>
                                            <li><span>インスタントウィン機能</span> </li>
                                            <li><span>UGCギャラリー機能</span> </li>
                                            <li><span>メール・チャットサポート</span> </li>
                                            <li><span>応募データの表示制限</span> 30,000</li>
                                            <li><span>応募ユーザーの上限数</span> 30,000</li>
                                            <li><span>リタッチロゴの表示</span> なし</li>
                                            <li><span>応募方法の制限</span> なし</li>
                                            <li><a href="#pricing-header" className="detailPricing" ><span>詳細を見る</span></a></li>
                                        </ul>
                                    </div>
                                    <div className="generic_price_btn clearfix">
                                        <a className="" href="">無料で試す</a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 col-sm-12">
                                <div className="generic_content active clearfix">
                                    <div className="generic_head_price clearfix">
                                        <div className="generic_head_content clearfix">
                                            <div className="head_bg"></div>
                                            <div className="head">
                                                <span>ビジネスプラン</span>
                                            </div>
                                        </div>
                                        <div className="generic_price_tag clearfix">
                                        <span className="price">
                                            <span className="sign">￥</span>
                                            <span className="currency">98,000</span>
                                            {/*<span className="cent">.99</span>*/}
                                            <span className="month">/月</span>
                                        </span>
                                        </div>
                                    </div>
                                    <div className="generic_feature_list">
                                        <ul>
                                            <li><span>キャンペーン機能</span> </li>
                                            <li><span>インスタントウィン機能</span> </li>
                                            <li><span>UGCギャラリー機能</span> </li>
                                            <li><span>メール・チャットサポート</span> </li>
                                            <li><span>応募データの表示制限</span> 300,000</li>
                                            <li><span>応募ユーザーの上限数</span> 300,000</li>
                                            <li><span>リタッチロゴの表示</span> なし</li>
                                            <li><span>応募方法の制限</span> なし</li>
                                            <li><a href="#pricing-header" className="detailPricing" ><span>詳細を見る</span></a></li>
                                        </ul>
                                    </div>
                                    <div className="generic_price_btn clearfix">
                                        <a className="" href="">無料で試す</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
                <div className="container">
                    <div className="membership-levels-table table-responsive">
                        <table className="table table-striped">
                            <thead className="price-header" id="pricing-header">
                                <tr>
                                    <th className="category">サポート
                                    </th>
                                    <th className="free-plan">
                                        <div >フリープラン</div>
                                        <div>￥<span className="plan-price">0</span>/月</div>
                                        <div className="price-tax">　</div>
                                            <div className="btn-box">
                                                <a href="/signup" className="default-btn">
                                                    無料で試す <span></span>
                                                </a>
                                            </div>
                                    </th>
                                    <th className="lite-plan">
                                        <div >ライトプラン</div>
                                        <div>￥<span className="plan-price">2,980</span>/月</div>
                                        <div className="price-tax">(税込 <span className="plan-price-tax"><small>￥</small>3,278<small>/月</small>)</span></div>
                                            <div className="btn-box">
                                                <a href="/signup" className="default-btn">
                                                    無料で試す <span></span>
                                                </a>
                                            </div>
                                    </th>
                                    <th className="basic-plan">
                                        <div >ベーシックプラン</div>
                                        <div>￥<span className="plan-price">9,800</span>/月</div>
                                        <div className="price-tax">(税込 <span className="plan-price-tax"><small>￥</small>10,780<small>/月</small>)</span></div>
                                            <div className="btn-box">
                                                <a href="/signup" className="default-btn">
                                                    無料で試す <span></span>
                                                </a>
                                            </div>
                                    </th >
                                    <th className="pro-plan">
                                        <div >プロプラン</div>
                                        <div>￥<span className="plan-price">39,800</span>/月</div>
                                        <div className="price-tax">(税込 <span className="plan-price-tax"><small>￥</small>43,780<small>/月</small>)</span></div>
                                            <div className="btn-box">
                                                <a href="/signup" className="default-btn">
                                                    無料で試す <span></span>
                                                </a>
                                            </div>
                                    </th>
                                    <th className="business-plan">
                                        <div>ビジネスプラン</div>
                                        <div>￥<span className="plan-price">98,000</span>/月</div>
                                        <div className="price-tax">(税込 <span className="plan-price-tax"><small>￥</small>107,800<small>/月</small>)</span></div>
                                            <div className="btn-box">
                                                <Link href="/signup" className="default-btn">
                                                    無料で試す <span></span>
                                                </Link>
                                            </div>
                                    </th>

                                </tr>
                            </thead>
                            <tbody>
                                <tr className="category">
                                    <td>Eメール<Question text={"Eメールでのお問い合わせが可能です。"} />
                                    </td>
                                    <td className="item-check"><i className='bx bx-check'></i></td>
                                    <td className="item-check"><i className='bx bx-check'></i></td>
                                    <td className="item-check"><i className='bx bx-check'></i></td>
                                    <td className="item-check"><i className='bx bx-check'></i></td>
                                    <td className="item-check"><i className='bx bx-check'></i></td>
                                </tr>
                                <tr>
                                    <td>チャット<Question text={"チャットでのお問い合わせが可能です。"} /></td>
                                    {/*<td className="item-none"><i className='bx bx-x'></i></td>*/}
                                    <td >-</td>
                                    <td >-</td>
                                    <td className="item-check"><i className='bx bx-check'></i></td>
                                    <td className="item-check"><i className='bx bx-check'></i></td>
                                    <td className="item-check"><i className='bx bx-check'></i></td>
                                </tr>
                            </tbody>
                            <thead className="price-header sub">
                            <tr>
                                <th className="category">機能</th>
                                <th className="free-plan">
                                </th>
                                <th className="lite-plan">
                                </th>
                                <th className="basic-plan">
                                </th>
                                <th className="pro-plan">
                                </th>
                                <th className="business-plan">
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>チームメンバー<Question text={`<span>チームメンバーとは、サイトの管理者が他のユーザーを招待し、<br />メンバーとし追加することでサイトの機能を使用できます。</span>`} /></td>
                                    <td>0</td>
                                    <td>0</td>
                                    <td>1</td>
                                    <td>3</td>
                                    <td>5</td>
                                </tr>
                                <tr>
                                    <td>同一応募方法<Question text={
                                        `<span>同じ応募方法を使用するための制限です。<br />
                                            例えばフォローしてほしいTwitterアカウントが３つある場合、<br/>
                                            応募方法を１つのキャンペーンに３つ追加できます。<br />
                                            <img src="`+duplicateEntries+`" alt="同一応募方法"/><br/>
                                            フリー・ライトプラン,だと１つのアカウントフォローしかできません。</span>`
                                    } /></td>
                                    <td>1</td>
                                    <td>1</td>
                                    <td>3</td>
                                    <td>制限なし</td>
                                    <td>制限なし</td>
                                </tr>
                                <tr>
                                    <td>ユーザー数の制限<Question text={`<span>応募したユーザー数の上限数です。<br />上限数に達するとユーザーを閲覧できなくなります。</span>`} /></td>
                                    <td>10</td>
                                    <td>300</td>
                                    <td>3,000</td>
                                    <td>30,000</td>
                                    <td>300,000</td>
                                </tr>
                                <tr>
                                    <td>ヘッダー画像<Question text={`<span>キャンペーンとインスタントウィンの応募フォームの画像の使用制限です。</span>`} /></td>
                                    <td >-</td>
                                    <td className="item-check"><i className='bx bx-check'></i></td>
                                    <td className="item-check"><i className='bx bx-check'></i></td>
                                    <td className="item-check"><i className='bx bx-check'></i></td>
                                    <td className="item-check"><i className='bx bx-check'></i></td>
                                </tr>
                                <tr>
                                    <td>レポート<Question text={`<span>キャンペーンとインスタントウィンでのレポート画面での閲覧制限です。<br/>フリープランとベーシックプランはレポートの取得条件を変更できません。</span>`} /></td>
                                    <td >-</td>
                                    <td >-</td>
                                    <td className="item-check"><i className='bx bx-check'></i></td>
                                    <td className="item-check"><i className='bx bx-check'></i></td>
                                    <td className="item-check"><i className='bx bx-check'></i></td>
                                </tr>
                                <tr>
                                    <td>カスタムフィールド<Question text={`<span>応募者の入力項目の変更制限です。<br/>ベーシックプラン以上で、応募者の入力項目を自由に変更できます。</span>`} /></td>
                                    <td >-</td>
                                    <td >-</td>
                                    <td className="item-check"><i className='bx bx-check'></i></td>
                                    <td className="item-check"><i className='bx bx-check'></i></td>
                                    <td className="item-check"><i className='bx bx-check'></i></td>
                                </tr>
                                <tr>
                                    <td>リタッチ提供の削除<Question text={`<span>Powered by retach.bizを削除できます。</span>`} /></td>
                                    <td >-</td>
                                    <td >-</td>
                                    <td >-</td>
                                    <td className="item-check"><i className='bx bx-check'></i></td>
                                    <td className="item-check"><i className='bx bx-check'></i></td>
                                </tr>
                            </tbody>
                            <thead className="price-header">
                            <tr>
                                <th className="category">キャンペーン</th>
                                <th className="free-plan">
                                </th>
                                <th className="lite-plan">
                                </th>
                                <th className="basic-plan">
                                </th>
                                <th className="pro-plan">
                                </th>
                                <th className="business-plan">
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>当選数<Question text={`<span>1つのキャンペーンの当選数の上限数になります。</span>`}/></td>
                                    <td>1</td>
                                    <td>100</td>
                                    <td>1,000</td>
                                    <td>10,000</td>
                                    <td>100,000</td>
                                </tr>
                                <tr>
                                    <td>応募データの表示制限<Question text={`<span>1つのキャンペーンの応募データの見ることができる上限数になります。<br/>上限値を超えた応募データだけ見えなくなります。キャンペーンの応募は可能です。</span>`}/></td>
                                    <td>10</td>
                                    <td>300</td>
                                    <td>3,000</td>
                                    <td>30,000</td>
                                    <td>300,000</td>
                                </tr>
                            </tbody>
                            <thead className="price-header">
                            <tr>
                                <th className="category">インスタントウィン</th>
                                <th className="free-plan">
                                </th>
                                <th className="lite-plan">
                                </th>
                                <th className="basic-plan">
                                </th>
                                <th className="pro-plan">
                                </th>
                                <th className="business-plan">
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>賞品・懸賞数<Question text={`<span>1つのインスタントウィンの賞品・懸賞数の上限数になります。</span>`}/></td>
                                    <td>1</td>
                                    <td>100</td>
                                    <td>1,000</td>
                                    <td>10,000</td>
                                    <td>100,000</td>
                                </tr>
                                <tr>
                                    <td>応募データの表示制限<Question text={`<span>1つのインスタントウィンの応募データを見ることができる上限数になります。<br/>上限値を超えた応募データだけ見えなくなります。キャンペーンの応募は可能です。</span>`}/></td>
                                    <td>10</td>
                                    <td>300</td>
                                    <td>3,000</td>
                                    <td>30,000</td>
                                    <td>300,000</td>
                                </tr>
                            </tbody>
                                <thead className="price-header">
                                <tr>
                                    <th className="category">ギャラリー</th>
                                    <th className="free-plan">
                                    </th>
                                    <th className="lite-plan">
                                    </th>
                                    <th className="basic-plan">
                                    </th>
                                    <th className="pro-plan">
                                    </th>
                                    <th className="business-plan">
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>UGCの表示<Question text={`<span>InstagramやTwitterで取得したコンテンツをHPやECサイトに埋め込み表示できる機能です。</span>`}/></td>
                                    <td>-</td>
                                    <td className="item-check"><i className='bx bx-check'></i></td>
                                    <td className="item-check"><i className='bx bx-check'></i></td>
                                    <td className="item-check"><i className='bx bx-check'></i></td>
                                    <td className="item-check"><i className='bx bx-check'></i></td>
                                </tr>
                                <tr>
                                    <td>上限取得数<Question text={`<span>全ギャラリーの合計取得上限数になります。</span>`}/></td>
                                    <td>-</td>
                                    <td>-</td>
                                    <td>1,000</td>
                                    <td>10,000</td>
                                    <td>50,000</td>
                                </tr>
                                <tr>
                                    <td>Instagram 更新頻度<Question text={`<span>投稿を取得する間隔です。</span>`}/></td>
                                    <td>-</td>
                                    <td>-</td>
                                    <td>1時間</td>
                                    <td>30分</td>
                                    <td>15分</td>
                                </tr>
                                <tr>
                                    <td>Instagram １取得上限数<Question text={`<span>１回で取得できる上限数です。</span>`}/></td>
                                    <td>-</td>
                                    <td>-</td>
                                    <td>250</td>
                                    <td>1,000</td>
                                    <td>2,000</td>
                                </tr>
                                <tr>
                                    <td>Twitter 更新頻度<Question text={`<span>リアルタイムで取得できますが<br/>数分の遅れが発生することもございます。</span>`}/></td>
                                    <td>-</td>
                                    <td>-</td>
                                    <td>リアルタイム</td>
                                    <td>リアルタイム</td>
                                    <td>リアルタイム</td>
                                </tr>
                            </tbody>
                            <thead className="price-header">
                            <tr>
                                <th className="category">応募方法</th>
                                <th className="free-plan">
                                </th>
                                <th className="lite-plan">
                                </th>
                                <th className="basic-plan">
                                </th>
                                <th className="pro-plan">
                                </th>
                                <th className="business-plan">
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>Twitter</td>
                                <td className="item-entry">
                                    {['エントリー','フォロー'].map(x => <div>{x}</div>)}
                                </td>
                                <td className="item-entry">
                                    {['エントリー','リツイート','ツイート','ハッシュタグ','フォロー','写真を選択','ツイート閲覧','いいね'].map(x => <div>{x}</div>)}
                                </td>
                                <td className="item-entry">
                                    {['エントリー','リツイート','ツイート','ハッシュタグ','フォロー','写真を選択','ツイート閲覧','いいね'].map(x => <div>{x}</div>)}
                                </td>
                                <td className="item-entry">
                                    {['エントリー','リツイート','ツイート','ハッシュタグ','フォロー','写真を選択','ツイート閲覧','いいね'].map(x => <div>{x}</div>)}
                                </td>
                                <td className="item-entry">
                                    {['エントリー','リツイート','ツイート','ハッシュタグ','フォロー','写真を選択','ツイート閲覧','いいね'].map(x => <div>{x}</div>)}
                                </td>
                            </tr>
                            <tr>
                                <td>LINE</td>
                                <td >-</td>
                                <td >-</td>
                                <td className="item-entry">
                                    {['友だち追加','シェア','ショップカード取得'].map(x => <div>{x}</div>)}
                                </td>
                                <td className="item-entry">
                                    {['友だち追加','シェア','ショップカード取得'].map(x => <div>{x}</div>)}
                                </td>
                                <td className="item-entry">
                                    {['友だち追加','シェア','ショップカード取得'].map(x => <div>{x}</div>)}
                                </td>
                            </tr>
                            <tr>
                                <td>Instagram</td>
                                <td className="item-entry">
                                    {['エントリー','投稿を閲覧'].map(x => <div>{x}</div>)}
                                </td>
                                <td className="item-entry">
                                    {['エントリー','いいね','フォロー','リポスト','プロフィールを閲覧','投稿を閲覧','写真を選択'].map(x => <div>{x}</div>)}
                                </td>
                                <td className="item-entry">
                                    {['エントリー','いいね','フォロー','リポスト','プロフィールを閲覧','投稿を閲覧','写真を選択'].map(x => <div>{x}</div>)}
                                </td>
                                <td className="item-entry">
                                    {['エントリー','いいね','フォロー','リポスト','プロフィールを閲覧','投稿を閲覧','写真を選択'].map(x => <div>{x}</div>)}
                                </td>
                                <td className="item-entry">
                                    {['エントリー','いいね','フォロー','リポスト','プロフィールを閲覧','投稿を閲覧','写真を選択'].map(x => <div>{x}</div>)}
                                </td>
                            </tr>
                            <tr>
                                <td>Facebook</td>
                                <td >-</td>
                                <td className="item-entry">
                                    {['エントリー','ページを閲覧','投稿を閲覧','写真を選択'].map(x => <div>{x}</div>)}
                                </td>
                                <td className="item-entry">
                                    {['エントリー','ページを閲覧','投稿を閲覧','写真を選択'].map(x => <div>{x}</div>)}
                                </td>
                                <td className="item-entry">
                                    {['エントリー','ページを閲覧','投稿を閲覧','写真を選択'].map(x => <div>{x}</div>)}
                                </td>
                                <td className="item-entry">
                                    {['エントリー','ページを閲覧','投稿を閲覧','写真を選択'].map(x => <div>{x}</div>)}
                                </td>
                            </tr>
                            <tr>
                                <td>Youtube</td>
                                <td >-</td>
                                <td className="item-entry">
                                    {['チャンネル閲覧登録','動画を投稿','チャンネル閲覧'].map(x => <div>{x}</div>)}
                                </td>
                                <td className="item-entry">
                                    {['チャンネル閲覧登録','動画を投稿','チャンネル閲覧'].map(x => <div>{x}</div>)}
                                </td>
                                <td className="item-entry">
                                    {['チャンネル閲覧登録','動画を投稿','チャンネル閲覧'].map(x => <div>{x}</div>)}
                                </td>
                                <td className="item-entry">
                                    {['チャンネル閲覧登録','動画を投稿','チャンネル閲覧'].map(x => <div>{x}</div>)}
                                </td>
                            </tr>
                            <tr>
                                <td>Twitch</td>
                                <td >-</td>
                                <td className="item-entry">
                                    {['エントリー','サブスクライバー','フォロー'].map(x => <div>{x}</div>)}
                                </td>
                                <td className="item-entry">
                                    {['エントリー','サブスクライバー','フォロー'].map(x => <div>{x}</div>)}
                                </td>
                                <td className="item-entry">
                                    {['エントリー','サブスクライバー','フォロー'].map(x => <div>{x}</div>)}
                                </td>
                                <td className="item-entry">
                                    {['エントリー','サブスクライバー','フォロー'].map(x => <div>{x}</div>)}
                                </td>
                            </tr>
                            <tr>
                                <td>ツイキャス</td>
                                <td >-</td>
                                <td className="item-entry">
                                    {['エントリー','サポート','動画を閲覧'].map(x => <div>{x}</div>)}
                                </td>
                                <td className="item-entry">
                                    {['エントリー','サポート','動画を閲覧'].map(x => <div>{x}</div>)}
                                </td>
                                <td className="item-entry">
                                    {['エントリー','サポート','動画を閲覧'].map(x => <div>{x}</div>)}
                                </td>
                                <td className="item-entry">
                                    {['エントリー','サポート','動画を閲覧'].map(x => <div>{x}</div>)}
                                </td>
                            </tr>
                            <tr>
                                <td>はてなブックマーク</td>
                                <td >-</td>
                                <td className="item-entry">
                                    {['エントリー','ブックマーク'].map(x => <div>{x}</div>)}
                                </td>
                                <td className="item-entry">
                                    {['エントリー','ブックマーク'].map(x => <div>{x}</div>)}
                                </td>
                                <td className="item-entry">
                                    {['エントリー','ブックマーク'].map(x => <div>{x}</div>)}
                                </td>
                                <td className="item-entry">
                                    {['エントリー','ブックマーク'].map(x => <div>{x}</div>)}
                                </td>
                            </tr>
                            <tr>
                                <td>Apple Music</td>
                                <td >
                                    -
                                </td>
                                <td >
                                    -
                                </td>
                                <td className="item-entry">
                                    {['プリアド'].map(x => <div>{x}</div>)}
                                </td>
                                <td className="item-entry">
                                    {['プリアド'].map(x => <div>{x}</div>)}
                                </td>
                                <td className="item-entry">
                                    {['プリアド'].map(x => <div>{x}</div>)}
                                </td>
                            </tr>
                            <tr>
                                <td>Spotify</td>
                                <td >
                                    -
                                </td>
                            
                                <td >
                                    -
                                </td>
                                <td className="item-entry">
                                    {['フォロー','セーブ','プリセーブ'].map(x => <div>{x}</div>)}
                                </td>
                                <td className="item-entry">
                                    {['フォロー','セーブ','プリセーブ'].map(x => <div>{x}</div>)}
                                </td>
                                <td className="item-entry">
                                    {['フォロー','セーブ','プリセーブ'].map(x => <div>{x}</div>)}
                                </td>
                            </tr>

                            <tr>
                                <td>シェア</td>
                                <td >-</td>
                                <td className="item-entry">
                                    {['LINE','Twitter','Facebook','メール'].map(x => <div>{x}</div>)}
                                </td>
                                <td className="item-entry">
                                    {['LINE','Twitter','Facebook','メール'].map(x => <div>{x}</div>)}
                                </td>
                                <td className="item-entry">
                                    {['LINE','Twitter','Facebook','メール'].map(x => <div>{x}</div>)}
                                </td>
                                <td className="item-entry">
                                    {['LINE','Twitter','Facebook','メール'].map(x => <div>{x}</div>)}
                                </td>
                            </tr>
                            <tr>
                                <td>シリアルナンバー<Question text={`<span>シリアルナンバーで複数を使用する場合の上限数です。単数の場合は上限数に制限はありません。</span>`}/></td>
                                <td>-</td>
                                <td>-</td>
                                <td className="item-entry">3,000</td>
                                <td className="item-entry">30,000</td>
                                <td className="item-entry">300,000</td>
                            </tr>
                            <tr>
                                <td>画像/動画 投稿
                                </td>
                                <td >-</td>
                                <td className="item-entry">
                                    {['Tiwtter','Instagram','Facebook'].map(x => <div>{x}</div>)}
                                </td>
                                <td className="item-entry">
                                    {['Tiwtter','Instagram','Facebook'].map(x => <div>{x}</div>)}
                                </td>
                                <td className="item-entry">
                                    {['Tiwtter','Instagram','Facebook'].map(x => <div>{x}</div>)}
                                </td>
                                <td className="item-entry">
                                    {['Tiwtter','Instagram','Facebook'].map(x => <div>{x}</div>)}
                                </td>
                            </tr>

                            <tr>
                                <td>質問</td>
                                <td className="item-entry">
                                    {['解答を入力','解答を選択','解答複数選択'].map(x => <div>{x}</div>)}
                                </td>
                                <td className="item-entry">
                                    {['解答を入力','解答を選択','解答複数選択'].map(x => <div>{x}</div>)}
                                </td>
                                <td className="item-entry">
                                    {['解答を入力','解答を選択','解答複数選択'].map(x => <div>{x}</div>)}
                                </td>
                                <td className="item-entry">
                                    {['解答を入力','解答を選択','解答複数選択'].map(x => <div>{x}</div>)}
                                </td>
                                <td className="item-entry">
                                    {['解答を入力','解答を選択','解答複数選択'].map(x => <div>{x}</div>)}
                                </td>
                            </tr>
                            <tr>
                                <td>ブログ</td>
                                <td >-</td>
                                <td className="item-entry">
                                    {['コメント','記事を投稿','RSS登録'].map(x => <div>{x}</div>)}
                                </td>
                                <td className="item-entry">
                                    {['コメント','記事を投稿','RSS登録'].map(x => <div>{x}</div>)}
                                </td>
                                <td className="item-entry">
                                    {['コメント','記事を投稿','RSS登録'].map(x => <div>{x}</div>)}
                                </td>
                                <td className="item-entry">
                                    {['コメント','記事を投稿','RSS登録'].map(x => <div>{x}</div>)}
                                </td>
                            </tr>
                            <tr>
                                <td>レシート<Question text={`<span>レシートの使用制限です。レシート応募はプラン料金とは別料金で１レシートに対して10円の料金が発生します。</span>`}/></td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td className="item-check"><i className='bx bx-check'></i><small>1レシートごと＋10円</small></td>
                            </tr>
                            <tr>
                                <td>クリックボーナス</td>
                                <td className="item-check"><i className='bx bx-check'></i></td>
                                <td className="item-check"><i className='bx bx-check'></i></td>
                                <td className="item-check"><i className='bx bx-check'></i></td>
                                <td className="item-check"><i className='bx bx-check'></i></td>
                                <td className="item-check"><i className='bx bx-check'></i></td>
                            </tr>
                            <tr>
                                <td>リンク
                                </td>
                                <td className="item-check"><i className='bx bx-check'></i></td>
                                <td className="item-check"><i className='bx bx-check'></i></td>
                                <td className="item-check"><i className='bx bx-check'></i></td>
                                <td className="item-check"><i className='bx bx-check'></i></td>
                                <td className="item-check"><i className='bx bx-check'></i></td>
                            </tr>
                            <tr>
                                <td>メール
                                </td>
                                <td >
                                    -
                                </td>
                                <td className="item-check"><i className='bx bx-check'></i></td>
                                <td className="item-check"><i className='bx bx-check'></i></td>
                                <td className="item-check"><i className='bx bx-check'></i></td>
                                <td className="item-check"><i className='bx bx-check'></i></td>
                            </tr>
                            <tr>
                                <td>アプリ</td>
                                <td >-</td>
                                <td className="item-check"><i className='bx bx-check'></i></td>
                                <td className="item-check"><i className='bx bx-check'></i></td>
                                <td className="item-check"><i className='bx bx-check'></i></td>
                                <td className="item-check"><i className='bx bx-check'></i></td>
                            </tr>
                            </tbody>
                        </table>

                    </div>
                </div>
            </PricingWrapper>
            <section className="ptb-100 top-question" >
                <div className="container">
                    <div className="section-title">
                    <span className="sub-title">
                        <StaticImage src="../../assets/images/star-icon.png" alt="features" />
                        Question
                    </span>
                        <h2 id="question">よくあるご質問</h2>
                    </div>
                    <div className="faq-accordion">
                        <Accordion allowMultipleExpanded={true}>
                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        無料でためすことはできますか？
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p>
                                        はい。無料で試すでリタッチの基本機能を確認できます。UGCギャラリー機能は有料プランからのご使用が可能です。
                                    </p>
                                </AccordionItemPanel>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        有料プランへの申し込みはどこからできますか？
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p>
                                        アカウント登録して頂き、「基本設定」→「プラン」のプラン変更から可能です。
                                    </p>
                                </AccordionItemPanel>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        1ヶ月だけ使用することはできますか？
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p>
                                        はい。次回の更新日までにプランをキャンセルしてください。キャンセルしない場合は更新日に自動更新となります。
                                    </p>
                                </AccordionItemPanel>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        プランを変更したらどうなりますか？
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p>
                                        すぐに新しいプランに切り替わります。アップグレードでは旧プランの残日数は日割りでのご返金となりますが、ダウングレードの場合は日割りでのご返金はございません。
                                    </p>
                                </AccordionItemPanel>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        キャンペーン開催中にプランの応募数上限を超えたら、キャンペーンは停止しますか？
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p>
                                        いいえ。キャンペーンは継続して応募が可能です。上限数を超えた応募データを見ることができなくなります。
                                    </p>
                                </AccordionItemPanel>
                            </AccordionItem>
                        </Accordion>
                    </div>
                </div>
            </section>
            <StartProject />
            <Footer />
        </Layout>
    )
};

export default Pricing